/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import HeroAlt from 'components/elements/HeroAlt'
import ButtonDefault from 'components/elements/ButtonDefault'

const Content = styled.div`
  & h1 {
    text-align: left;
    color: #000000;
    font-style: normal;
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  & h2 {
    font-size: ${(props) => props.theme.font.size.xl};
  }
`

// const StyledBoxDefault = styled(BoxDefault)`
//   height: auto;
// `

export const pageQuery = graphql`
  query ($id: String) {
    page: wpVacature(id: { eq: $id }) {
      title
      uri
      content
      vacancyrecap {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        story {
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { vacancyrecap, seo },
  },
}) => (
  <Layout>
    <SEO seo={seo} />
    <HeroAlt src={vacancyrecap.banner.image} small>
      BAANBREKEND BOUWEN
    </HeroAlt>
    <section>
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-8 py-3 pr-5">
            <Content className="text-justify">
              <ParseContent content={vacancyrecap.story.description} />
              <ButtonDefault to="/solliciteren">
                Solliciteer direct!
              </ButtonDefault>
            </Content>
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
    </section>
  </Layout>
)

export default PageTemplate
